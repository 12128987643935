import React from 'react';
import PropTypes from 'prop-types';

import Chevron from 'assets/svg/icons/chevron.svg';

import s from './PolicyMoreButton.scss';

const PolicyMoreButton = ({ onClick, text, open }) => (
  <div className={s('policyMoreButton', { open })}>
    <button className={s.policyMoreButton__button} onClick={onClick}>
      <span className={s.policyMoreButton__text}>{text}</span>
      <Chevron className={s.policyMoreButton__chevron} />
    </button>
  </div>
);

PolicyMoreButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  open: PropTypes.bool,
};

PolicyMoreButton.defaultProps = {
  onClick: () => null,
};

export default PolicyMoreButton;
