import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container } from 'components/layout';

import Principle from './Principle';

import s from './Principles.scss';

export default class Principles extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    items: PropTypes.array,
  }

  static defaultProps = {
    heading: null,
    text: null,
    items: null,
  }

  render() {

    const {
      heading,
      text,
      items,
    } = this.props;

    return (
      <div className={s.principles}>
        <Container>
          <h2 className={s.principles__heading}>{heading}</h2>
          <p className={s.principles__text}>{text}</p>
          <ol className={s.principles__row}>
            {items && items.map((item, i) => (
              <li
                key={item}
                className={s.principles__item}
              >
                <Principle index={i} text={item} />
              </li>
            ))}
          </ol>
        </Container>
      </div>
    );
  }
}
