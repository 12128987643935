import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Markdown from 'components/markdown';
import Icon from 'components/icon';
import kebabCaseIcon from 'utils/kebab-case-icon';
import { PolicyMoreButton } from '.';

import s from './Policy.scss';

export default class Policy extends Component {

  state = {
    more: false,
  }

  toggleMore = () => {
    this.setState({
      more: !this.state.more,
    });
  }

  static propTypes = {
    heading: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string,
    moreText: PropTypes.string,
  }

  render() {
    const { heading, icon, text, moreText } = this.props;

    const buttonText = this.state.more ? 'Read Less' : 'Read More';

    const moreButton = (
      <PolicyMoreButton onClick={this.toggleMore} open={this.state.more} text={buttonText} />
    );

    return (
      <div className={s.policy}>
        <h2 className={s.policy__heading}>
          {icon && (
            <Icon id={kebabCaseIcon(icon)} className={s.policy__icon} />
          )}
          {heading}
        </h2>
        <Markdown source={text} className={s.policy__text} />
        {moreText && (
          <Fragment>
            {moreButton}
            <div className={s('policy__more', { active: this.state.more })}>
              <Markdown source={moreText} className={s.policy__text} />
              {moreButton}
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}
