import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _get from 'lodash/get';
import LazyLoad from 'react-lazyload';

import extractMeta from 'utils/extract-meta';
import DocumentGroup from 'components/document-group';
import HeroIntro from 'components/hero-intro';
import Modules from 'containers/modules';

import Principles from './components/principles';
import Visual from './components/visual';
import Policy from './components/policy';
import SEO from 'components/seo';

export default class Privacy extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  render() {
    const { page } = this.props.data;
    const { pathname } = this.props.location;

    return (
      <Fragment>
        <SEO {...extractMeta(page)} pathname={pathname} image={_get(page, 'heroImageBlock.image.file.url')} article />

        <HeroIntro
          heading={page.heroHeading}
          text={page.heroText.text}
          image={{
            src: _get(page.heroImageBlock, 'image.file.url'),
            alt: _get(page.heroImageBlock, 'image.description'),
            width: _get(page.heroImageBlock, 'image.file.details.image.width'),
            height: _get(page.heroImageBlock, 'image.file.details.image.height'),
          }}
          heading2={_get(page.heroImageBlock, 'heading')}
          text2={_get(page.heroImageBlock, 'text.text')}
          cta={{
            text: _get(page.heroImageBlock, 'ctaText'),
            url: _get(page.heroImageBlock, 'ctaUrl'),
          }}
        />

        <Principles
          heading={page.principlesHeading}
          text={page.principlesText.text}
          items={[
            page.principle01,
            page.principle02,
            page.principle03,
          ]}
        />

        <LazyLoad once offset={500}>
          <Modules list={[page.imageBlock]} />
        </LazyLoad>

        <LazyLoad once offset={500}>
          <Visual
            heading={_get(page.visualImageBlock, 'heading')}
            text={_get(page.visualImageBlock, 'text.text')}
            cta={{
              text: _get(page.visualImageBlock, 'ctaText'),
              url: _get(page.visualImageBlock, 'ctaUrl'),
            }}
            image={{
              src: _get(page.visualImageBlock, 'image.file.url'),
              alt: _get(page.visualImageBlock, 'image.description'),
              width: _get(page.visualImageBlock, 'image.file.details.image.width'),
              height: _get(page.visualImageBlock, 'image.file.details.image.height'),
            }}
          />
        </LazyLoad>

        <DocumentGroup
          sections={page.policySections.map(item => item.heading)}
          heading={page.policyHeading}
          text={page.policyText.text}
        >
          {page.policySections.map(section => (
            <Policy
              key={section.id}
              heading={section.heading}
              icon={section.icon}
              text={_get(section.text, 'text')}
              moreText={_get(section.moreText, 'text')}
            />
          ))}
        </DocumentGroup>

        <Modules list={[page.cta]} />
      </Fragment>
    );
  }
}
