import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import Image from 'components/image';
import { Container } from 'components/layout';

import s from './Visual.scss';

export default class Visual extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    cta: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  }

  static defaultProps = {
    heading: '',
    text: '',
    image: {},
  }

  render() {
    const { heading, text, cta, image } = this.props;

    return (
      <div className={s.visual}>
        <Container>
          <h2 className={s.visual__heading}>{heading}</h2>
          {text &&
            <p className={s.visual__text}>{text}</p>
          }
          {cta.text &&
            <Button to={cta.url} color="transparent">{cta.text}</Button>
          }
        </Container>
        {image &&
          <div className={s.visual__imageWrap}>
            <div className={s.visual__image}>
              <Image
                src={image.src}
                alt={image.alt}
                width={image.width}
                height={image.height}
              />
            </div>
          </div>
        }
      </div>
    );
  }
}
