import React from 'react';
import PropTypes from 'prop-types';

import s from './Principle.scss';

const Principle = ({ index, text }) => (
  <div className={s.principle}>
    <div className={s.principle__number}>0{index + 1}</div>
    <p className={s.principle__text}>{text}</p>
  </div>
);

Principle.propTypes = {
  index: PropTypes.number,
  text: PropTypes.string,
};

export default Principle;
