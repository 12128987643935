import { graphql } from 'gatsby';
import Privacy from 'routes/privacy/Privacy';

export default Privacy;

export const query = graphql`
  query Privacy {
    page: contentfulPagePrivacy {
      pageTitle
      metaDescription
      metaImage {
        ...image
      }
      heroHeading: heroTitle
      heroText: heroBody {
        text: heroBody
      }
      heroImageBlock {
        ...moduleList
      }
      principlesHeading: principlesTitle
      principlesText: principlesBody {
        text: principlesBody
      }
      principle01
      principle02
      principle03
      imageBlock {
        ...moduleList
      }
      visualImageBlock {
        ...moduleList
      }
      policyHeading: policyTitle
      policyText: policyBody {
        text: policyBody
      }
      policySections {
        id
        heading: title
        icon
        text: body {
          text: body
        }
        moreText: readMoreBody {
          text: readMoreBody
        }
      }
      cta {
        ...moduleList
      }
    }
  }
`;
